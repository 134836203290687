import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image"
import { graphql, Link }  from 'gatsby'
import UnderstandTheDifference from '../components/UnderstandTheDifference'

import { btnExpert } from '../components/styles/ContainedButton.styles'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import Hr from '../components/Hr'
import H3 from '../components/H3'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Body2 from './../components/Body2'
import EntreEmContato from '../images/cloud-monitor/fale_com_nossos_especialistas copy@4x.png'
import ArrowR from '@material-ui/icons/ArrowForward'
import IcoCloud from '@material-ui/icons/Cloud'
import IcoDvr from '@material-ui/icons/Dvr'
import IcoStorage from '@material-ui/icons/Storage'
import IcoLock from '@material-ui/icons/Lock'
import ScrollAnim from 'rc-scroll-anim'

import { Container } from '../components/styles/Layout.styles'
import { IdProtectList, contactExpert, contentExpert, titleExpert, precosIcons } from '../components/styles/ProductCloud.styles'
import { titleStyle } from '../components/styles/InternalPage.styles'
import { 
  termosBoxWhite 
} from '../components/styles/Contact.styles'
import CloudMonitorForm from '../components/FormWhiteItens'
import { validateRequiredEmail } from '../utils/validations';
const fields = [
  {
    label: 'Nome',
    name: 'your-name', 
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true,
    validate: validateRequiredEmail,
  },
  {
    label: 'Empresa',
    name: 'your-company',
    type: 'text',
    required: true
  },
  {
    label: 'CPNJ',
    name: 'your-cnpj',
    type: 'text',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: (
      <span css={termosBoxWhite}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

const initialModals = {
  support: false,
  moreInfo: false
}

export const PageTemplate = ({
  data,
  ...rest
}) => {
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }
  return (
    <Fragment>
      <section>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'row']}
              mt={[50]}
              mb={[50]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                display={['none', 'none', 'flex']}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 450]}
                >
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
              </Column>
              <Column
                width={[1, 1, 5/6, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                >
                  <H4
                    style={{
                      fontSize: 11.8,
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontWeight: 600,
                      lineHeight: 1.36,
                      letterSpacing: 2,
                      marginBottom: 8
                    }}
                  >
                    CLOUD MONITOR HOSTDIME
                  </H4>
                  <H3
                    color='black'
                    variant='highEmphasis'
                    mb={[3, 5, 3]}
                    css={titleStyle}
                    maxWidth={['90%', 328, 416, 500]}
                  >
                    Monitoramento completo de toda a sua infraestrutura
                  </H3>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    maxWidth={['90%', 422, 416, 500]}
                  >
                    Serviço de monitoramento de sua infraestrutura de TI, não importa onde ela esteja, das mais simples às mais complexas, de acordo com a sua necessidade, correlacionando os logs de forma inteligente, intuitiva e fácil de usar.
                  </Body2>
                  <Div
                    display={['block', 'block', 'none']}
                    mt={[3]}
                    maxWidth={[310, 310, 327, 450]}
                  >
                    <Img
                      fluid={data.image1.childImageSharp.fluid}
                      loading='eager'
                    />
                  </Div>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
      </section>
      <section>
        <Container>
          <Row
            mb={50}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              maxWidth={[320, 400, 890, 1028]}
              css={contactExpert}>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <Div
                  maxWidth={['100%', 400]}
                  alignItems={['center']}
                  css={contentExpert}
                >
                  <H6
                    color='white'
                    variant='highEmphasis'
                    maxWidth={[328, 422, 300, 425]}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Já pensou em monitorar toda a rede da sua empresa em uma dashboard customizada para suas necessidades?
                  </H6>
                </Div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
                mb={[50, 50]}
                mt={[0, 0, 55, 55]}
              >
                <Div
                  maxWidth={[240]}
                  style={{
                    margin: '0 auto',
                    alignItems: 'center'
                  }}
                >
                  <div css={titleExpert}>
                    Fale com nossos especialistas.
                  </div>
                  <ScrollAnim.Link
                    to='fale-com-nossos-especialistas'
                    offsetTop='120'
                    css={btnExpert}
                  >
                    <div>
                      QUERO SABER MAIS
                    </div>
                  </ScrollAnim.Link>
                </Div>
              </Column>
            </Div>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row
            mt={[40, 70]}
            mb={[40, 50]}
            justifyContent={['left', 'center']}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <H5
              color='black'
              variant='highEmphasis'
              maxWidth={['90%', 400, 600, 600]}
              style={{
                textAlign: 'center'
              }}
            >
              Monitore em tempo real o que realmente importa: toda a sua infraestrutura de TI.
            </H5>
          </Row>
          <Row
            mt={30}
          >
            <Div 
              flexDirection={['column', 'row']}
              justifyContent={['center', 'flex-end']}
              alignItems={['center']}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                mb={[8, 8,  8,  0]}
                mr={[0 , 64, 96]}
              >
                <Div
                  alignItems={['center']}
                >
                  <div css={precosIcons}>
                    <IcoCloud 
                      style={{
                        color: '#fff'
                      }}
                    />
                  </div>
                  <H6
                    color='black'
                    variant='blackMediumEmphasis'
                    mb={[3]}
                    style={{
                      textAlign: 'center',
                      color: 'rgba(0, 0, 0, 0.6)'
                    }}
                  >
                    Cloud
                  </H6>
                </Div>
              </Column>
              <Column
                mb={[8, 8, 8,  0]}
                mr={[0 , 64, 48]}
              >
                <Div
                  alignItems={['center']}
                >
                  <div css={precosIcons}>
                    <IcoDvr 
                      style={{
                        color: '#fff'
                      }}
                    />
                  </div>
                  <H6
                    color='black'
                    variant='blackMediumEmphasis'
                    mb={[3]}
                    style={{
                      textAlign: 'center',
                      color: 'rgba(0, 0, 0, 0.6)'
                    }}
                  >
                    Site
                  </H6>
                </Div>
              </Column>
            </Div>
            <Div 
              flexDirection={['column', 'row']}
              justifyContent={['center', 'flex-start']}
              alignItems={['center']}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                mb={[8, 8,  8,  0]}
                ml={[0, 0, 0, 48]}
                mr={[0 , 64, 96]}
              >
                <Div
                  alignItems={['center']}
                >
                  <div css={precosIcons}>
                    <IcoStorage 
                      style={{
                        color: '#fff'
                      }}
                    />
                  </div>
                  <H6
                    color='black'
                    variant='blackMediumEmphasis'
                    mb={[3]}
                    style={{
                      textAlign: 'center',
                      color: 'rgba(0, 0, 0, 0.6)'
                    }}
                  >
                    Servidores
                  </H6>
                </Div>
              </Column>
              <Column
                mb={[8, 8, 8,  0]}
              >
                <Div
                  alignItems={['center']}
                >
                  <div css={precosIcons}>
                    <IcoLock 
                      style={{
                        color: '#fff'
                      }}
                    />
                  </div>
                  <H6
                    color='black'
                    variant='blackMediumEmphasis'
                    mb={[3]}
                    style={{
                      textAlign: 'center',
                      color: 'rgba(0, 0, 0, 0.6)'
                    }}
                  >
                    Intranet
                  </H6>
                </Div>
              </Column>
            </Div>
          </Row>
          <Row
            mt={[30]}
            mb={[50]}
            justifyContent={['left', 'center']}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <H5
              color='black'
              variant='highEmphasis'
              maxWidth={['90%', 400, 600, 600]}
              style={{
                textAlign: 'center'
              }}
            >
              E muito mais
            </H5>
          </Row>
        </Container>
        <Div 
          alignItems={['center']}
        >
          <Hr
            lineColor='lightPeriwinkle'
            m={['0']}
            maxWidth={[328, 400, 890, 1024]}
            style={{
              opacity: 0.5
            }}
          />
        </Div>
      </section>
      <section>
        <Container>
          <Row
            mt={[40, 70]}
            mb={[30, 50]}
            justifyContent={['center']}
          >
            <H5
              color='black'
              variant='highEmphasis'
              maxWidth={['90%', 422, 400, 890]}
              style={{
                textAlign: 'center'
              }}
            >
              Com o Cloud Monitor, você garante:
            </H5>
          </Row>
          <Row
            mt={30}
            mb={46}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              maxWidth={['100%', '100%', 855, 856]}
              justifyContent={['center']}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div
                  marginBottom={[0, 30]}
                  css={IdProtectList}
                >
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Maior eficiência de recursos</span>
                    <Body2
                      selected
                      on='onSurface'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      Garanta que seus recursos de rede e hardware não sejam subutilizados ou super utilizados. 
                    </Body2>
                  </Div>
                </div>
                <div css={IdProtectList}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Tranquilidade e foco</span>
                    <Body2
                      selected
                      on='onSurface'
                      variant='mediumEmphasis'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      Com a ajuda do Cloud Monitor sua equipe de TI será ainda mais estratégica para entregar os melhores resultados.
                    </Body2>
                  </Div>
                </div>
                <div css={IdProtectList}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Proatividade</span>
                    <Body2
                      selected
                      on='onSurface'
                      variant='mediumEmphasis'
                      order={[2, 2, 1]}
                      mb={[4, 4, 0, 0]}
                    >
                      Personalize o monitoramento da sua infraestrutura para que o NOC 24x7x365 possa agir de forma preventiva e proativa.
                    </Body2>
                  </Div>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={IdProtectList}>
                  <ArrowR 
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <Div
                    maxWidth={['90%', 370]}
                  >
                    <span>Controle do ambiente e dos acessos</span>
                    <Body2
                      selected
                      on='onSurface'
                      variant='mediumEmphasis'
                      order={[2, 2, 1]}
                    >
                      Obtenha uma visão macro do TI da sua empresa, monitorando desde o volume trafegado até o IP específico que está consumindo maior banda que o usual, Identifique o item de hardware, rede ou serviço que pode estar com um consumo excessivo de recursos, afetando sua aplicação ou experiência do usuário.
                    </Body2>
                  </Div>
                </div>
              </Column>
            </Div>
          </Row>
        </Container>
        <Div 
          alignItems={['center']}
        >
          <Hr
            lineColor='lightPeriwinkle'
            m={['0']}
            maxWidth={[328, '100%' ,680 , 1024]}
            style={{
              opacity: 0.5
            }}
          />
        </Div>
      </section>
      <section style={{ backgroundColor: '#9013fe' }} id='fale-com-nossos-especialistas'>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'column', 'row']}
              mt={[50, 70]}
              mb={[50, 70]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center', 'end']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                mt={[10]}
                display={['flex']}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 450]}
                  alignItems={['center']}
                >
                  <img 
                    src={EntreEmContato} 
                    style={{
                      width: 120
                    }}
                  />
                  <H5
                    color='white'
                    variant='highEmphasis'
                    mt={[3]}
                    mb={[5]}
                    maxWidth={['90%', 422, 300, 300]}
                    style={{
                      textAlign: 'center',
                      fontWeight: 500
                    }}
                  >
                    Entre em contato e receba um orçamento sem compromisso dos nossos especialistas.
                  </H5>
                </Div>
              </Column>
              <Column
                width={[1, 1, 5/6, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                  maxWidth={['100%', 422, 400, 400]}
                >
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    order={[2, 2, 1]}
                    maxWidth={['100%', 422, 416, 416]}
                    alignItems={['center', 'center', 'normal', 'normal']}
                  >
                    <Div
                      marginTop={90}
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      <CloudMonitorForm
                        fields={fields}
                        formId={4360}
                        submitText='ENVIAR'
                      />
                    </Div>
                  </Body2>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
      </section>
      <UnderstandTheDifference
        visible={modals.moreInfo}
        onClose={() => setModalVisibility('moreInfo')(false)}
      />
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => { 
  return (
    <Layout>
      <Seo 
        title='Cloud Monitor HostDime'
        description='Monitore toda a infraestrutura de TI da sua empresa em uma dashboard customizada para suas necessidades com o Cloud Monitor HostDime'
      />
      <PageTemplate
        title='Cloud Monitor HostDime'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-monitor/cloud_monitor_hostdime@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
